<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <div v-on="on">
        <v-img
          v-if="!isWorkloadOnline || !canAccess('UI_REMOTE_CONN:CONNECT')"
          :id="`iiotWorkloadTableActionsConnectionsImageDisabled_${index}`"
          :src="`/img/DisabledConnections.svg`"
          max-height="25px"
          max-width="25px"
          class="ml-5"
        />
        <v-img
          v-else
          :id="`iiotWorkloadTableActionsConnectionsImage_${index}`"
          :src="`/img/Connections.svg`"
          max-height="25px"
          max-width="25px"
          class="ml-5"
          @click.native.stop="openRemoteConnectionsDialog()"
        />
      </div>
    </template>
    <span>{{ $t('nodes.information.connectionsTooltip') }}</span>
    <remote-connection-dialog
      v-if="workloadId === selectedId"
      :node="selectedNode"
      :is-open-close-init-connection-dialog="isOpened"
      type="workload"
      :workload-id="workloadId"
      :version-id="versionId"
      :empty-list-text="$t('remoteConnection.table.rcWorkloadEmptyList')"
      @cancel-event="closeRemoteConnectionWorkloadDialog"
    />
  </v-tooltip>
</template>

<script>
import remoteConnectionDialog from '@/components/remote-connection/remoteConnectionDialog.vue';
import { STATUSES } from '../../../constants';

export default {
  components: { remoteConnectionDialog },
  props: {
    tableItem: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    selectedNode: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isOpened() {
      return this.$store.getters['remote-connection/isOpenCloseRemoteConnectionsWorkloadDialog'];
    },
    workloadId() {
      return this.tableItem.workloadId;
    },
    versionId() {
      return this.tableItem._versionId;
    },
    selectedId() {
      return this.$store.getters['remote-connection/getSelectedWorkloadId'];
    },
    isWorkloadOnline() {
      const status = this.tableItem._currentStatus ? this.tableItem._currentStatus.toUpperCase() : '';
      return STATUSES[status] === STATUSES.STARTED;
    },
  },
  methods: {
    async openRemoteConnectionsDialog() {
      await this.$store.dispatch('remote-connection/clear_remote_connection_list_state');
      await this.$store.dispatch('remote-connection/getWorkloadVersionRc', {
        workloadId: this.tableItem.workloadId,
        versionId: this.tableItem._versionId,
      });
      // store workload type
      await this.$store.dispatch('remote-connection/set_workload_type', this.tableItem.type);
      const versionRC = await this.$store.getters['remote-connection/getWlRc'];
      await this.$store.dispatch('remote-connection/fetch', { remoteConnections: versionRC });
      await this.$store.dispatch('remote-connection/set_selected_node_or_wl_id', {
        type: 'workload',
        selectedId: this.tableItem.workloadId,
      });
      await this.$store.dispatch('remote-connection/openCloseRemoteConnectionsWorkloadDialog', true);
    },
    async closeRemoteConnectionWorkloadDialog() {
      this.$store.dispatch('remote-connection/set_selected_node_or_wl_id', {
        type: 'workload',
        selectedId: '',
      });
      await this.$store.dispatch('remote-connection/openCloseRemoteConnectionsWorkloadDialog', false);
    },
  },
};
</script>

<style scoped>

</style>
